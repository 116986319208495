<template>
    <div class="topAdv" :style="topBtnColor? 'background-color:transparent' :'background-color:black;'">
        <!-- 顶部导航 -->
        <div class="top_adv_box">
            <!-- 顶部导航左侧图片 -->
            <img
                    src="../assets/indexImg/homeLogo.png"
                    alt=""
                    @click="goIndex"
                    class="companyName"
            />
            <div class="right_box">
                <!-- 右侧标签页 -->
                <!-- hidden-xs-only -->
                <ul class="adv_right_box ">
                    <li v-for="(item,index) in advName" :key="index" :class="vuexAdvActive==index?'active_color':''"
                        @click="swichPage(index)">
                        {{item}}
                        <div :class="activeName==index?'nav_bg_box nav_bg_active':'nav_bg_box nav_bg_hover'">
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>
<script>
    import {mapState} from 'vuex'

    export default {
        name: "TopAdv",
        data() {
            return {
                activeName: 0,
                advName: ["首页", "产品", "星火实验室", "新闻中心", "关于我们"],
                topBtnColor: true,
            };
        },
        methods: {
            // 点击导航左侧图片跳转首页
            goIndex() {
                this.$router.push({
                    path: '/'
                })
            },
            // 点击导航栏跳转页面
            swichPage(index) {
                // eslint-disable-next-line no-debugger
                // debugger
                this.$store.commit('modifyVuexAdvActive', {vuexAdvActive: index})
                this.activeName = index
                switch (index) {
                    case 0:
                        this.$router.push({path: "/"})
                        break;
                    case 1:
                        this.$router.push({path: "/productPage"});
                        break;
                    case 2:
                        this.$router.push({path: "/laboratory"})
                        break;
                    case 3:
                        this.$router.push({path: "/news"})
                        break;
                    case 4:
                        this.$router.push({path: "/aboutUs"})
                        break;
                }
            },
        },
        props: {
            bgnColor: Number,
        },
        created() {
            // eslint-disable-next-line no-debugger
            // debugger
            this.activeName= this.vuexAdvActive;
            // 判断是否首页 改变导航背景
            if (this.bgnColor === 0) {
                this.topBtnColor = true
            } else {
                this.topBtnColor = false
            }
            // 判定当行当前选中


        },
        computed: {
            ...mapState(['vuexAdvActive'])
        }
    };
</script>
<style scoped lang="less">

    .top_adv_box {
        min-width: 1200px;
        display: flex;
        height: 75px;
        justify-content: space-around;
        align-items: center;

        &:deep(.el-tabs__active-bar) {
            background-color: transparent;
        }

        &:deep(.el-tabs__nav-scroll) {
            margin-left: 10%;
        }
    }

    .companyName {
        height: 40px;
        cursor: pointer;
    }

    .adv_right_box {
        width: 100%;

        li {
            float: left;
            padding: 0 40px;
            color: white;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                color: rgb(113, 207, 255);
            }

            transition: all .5s;

            &:hover .nav_bg_hover {
                background-image: url(../assets/indexImg/nav_bg.png);
            }
        }
    }

    .active_color {
        color: rgb(113, 207, 255) !important;
    }

    .nav_bg_box {
        position: relative;
        left: -32px;
        top: -5px;
        width: 62px;
        height: 15px;
    }

    .nav_bg_active {
        background-image: url(../assets/indexImg/nav_bg.png);
    }

</style>
